<template>
  <section>
    <div class="header">
      <div class="header_title">
        <h1>Теги для витрины</h1>
      </div>
    </div>
    <div class="btns">
      <v-btn color="#5d4bd0" dark @click="openAddModal">
        Добавить тэг
        <v-icon>mdi-plus</v-icon>
      </v-btn>
    </div>
    <loader v-if="loading" />
    <div class="conteiner">
      <!-- Карточка тега -->
      <v-card v-for="tag in TAGS" :key="tag.id" class="conteiner__tag">
        <v-card-text class="pb-0">
          <p class="conteiner__tag_title">{{ tag.name }}</p>
          <p v-if="tag.description">Описание : {{ tag.description }}</p>
          <p v-if="tag.category">Категория : {{ tag.category.name }}</p>
          <p v-if="tag.department">Филиал : {{ tag.department.name }}</p>
          <p v-if="tag.pageLink">Ссылка : {{ tag.pageLink }}</p>
          <p v-if="tag.product">Продукт : {{ tag.product.name }}</p>
          <!-- Если у тега есть изображение, показываем его с кнопкой удаления -->
          <div v-if="tag.imageUrl" class="tag-image-container">
            <img :src="tag.imageUrl" alt="Изображение тега" style="max-width: 100px;" />
            <v-btn icon small color="red" @click="deleteTagImage(tag)">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </div>
        </v-card-text>
        <v-card-actions class="pt-0">
          <v-btn icon @click="confirmDelete(tag.id)">
            <v-icon color="error" class="conteiner_icon">mdi-trash-can</v-icon>
          </v-btn>
          <v-btn icon @click="openModalToRedact(tag)">
            <v-icon color="#5d4bd0" class="conteiner_icon">mdi-lead-pencil</v-icon>
          </v-btn>
        </v-card-actions>
      </v-card>

      <!-- Модальное окно для создания/редактирования тега -->
      <v-dialog v-model="modal" activator="parent" width="60%">
        <v-card class="modal">
          <v-card-title class="modal_title">
            {{ isRedacting ? "Изменение " : "Добавление " }} тега
          </v-card-title>
          <v-form ref="vitrinModal">
            <v-text-field
              v-model="newTag.name"
              :rules="[$validate.required]"
              label="Название"
              required
            ></v-text-field>
            <v-text-field
              v-model="newTag.description"
              :rules="[$validate.required]"
              label="Описание"
              required
            ></v-text-field>
            <v-text-field
              v-model="newTag.productId"
              label="ID продукта"
              @change="changeField('productId')"
            ></v-text-field>
            <v-text-field
              v-model="newTag.pageLink"
              label="Ссылка"
              @change="changeField('pageLink')"
            ></v-text-field>
            <v-select
              v-model="newTag.departmentId"
              label="Филиал"
              :items="DEPARTMENT.cityDepartment"
              item-text="name"
              item-value="id"
              @change="changeField('departmentId')"
            ></v-select>
            <v-autocomplete
              v-model="newTag.categoryId"
              :items="CATEGORY"
              item-text="name"
              item-value="id"
              label="Категория"
              @change="changeField('categoryId')"
            />
            <v-select
              v-model="newTag.cityIds"
              label="Города"
              multiple
              :items="CITY.allCity"
              item-text="name"
              item-value="id"
            ></v-select>
            <!-- Поле загрузки SVG-изображения -->
            <v-file-input
              :key="fileInputKey"
              label="SVG изображение"
              accept="image/svg+xml"
              prepend-icon="mdi-image"
              @change="onFileChange($event)"
              :clearable="true"
            ></v-file-input>
            <div v-if="preview">
              <img :src="preview" alt="Предпросмотр изображения" style="max-width: 100px;" />
            </div>
            <!-- При редактировании, если уже есть изображение, показываем его с кнопкой удаления -->
            <div v-if="isRedacting && existingImage">
              <p>Текущее изображение:</p>
              <img :src="existingImage.url" alt="Текущее изображение" style="max-width: 100px;" />
              <v-btn small color="red" @click="deleteExistingImage">
                Удалить
              </v-btn>
            </div>
          </v-form>
          <v-card-actions class="modal_actions">
            <v-btn @click="closeModal">Закрыть</v-btn>
            <v-btn color="#5d4bd0" dark @click="isRedacting ? changeTag() : addNewTag()">
              {{ isRedacting ? "Изменить" : "Добавить" }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <!-- Диалог подтверждения удаления тега -->
      <delete-dialog
        :show="showDelete"
        @close-modal="showDelete = false"
        @delete-modal="deleteTag()"
      />
    </div>
  </section>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import showMessage from "@/Functions/message";
import DeleteDialog from "@/components/DeleteDialog";
import loader from "@/components/Loader";
import api from "@/api";

export default {
  components: { DeleteDialog, loader },
  data() {
    return {
      loading: false,
      showDelete: false,
      modal: false,
      isRedacting: false,
      tagId: 0,
      newTag: {},
      // Для загрузки нового изображения
      imageFile: null,
      preview: null,
      // Для хранения существующего изображения при редактировании
      existingImage: null,
      // Ключ для принудительного обновления компонента v-file-input
      fileInputKey: Date.now(),
      titleRules: [
        (value) => (value ? true : "Введите тэг"),
        (value) => (value && value.length <= 20 ? true : "Слишком длинный тэг"),
      ],
    };
  },
  computed: {
    ...mapGetters({
      TAGS: "VitrinTags/STATE",
      CITY: "City/STATE",
      USER: "User/STATE",
      DEPARTMENT: "Department/STATE",
      CATEGORY: "Category/PRODUCTS_CATEGORY",
    }),
  },
  watch: {
    modal(newVal) {
      if (!newVal) {
        // Сброс всех полей при закрытии модального окна
        this.isRedacting = false;
        this.newTag = {};
        this.imageFile = null;
        this.preview = null;
        this.existingImage = null;
        // Обновляем ключ, чтобы сбросить состояние v-file-input
        this.fileInputKey = Date.now();
        if (this.$refs.vitrinModal) {
          this.$refs.vitrinModal.resetValidation();
        }
      }
    },
  },
  mounted() {
    if (!this.CATEGORY.length) {
      this.getCategory();
    }
    this.setValues();
  },
  methods: {
    ...mapActions({
      getAllTags: "VitrinTags/GET_ALL_TAGS",
      getCategory: "Category/GET_PRODUCTS_CATEGORY",
    }),
    async setValues() {
      this.loading = true;
      await this.getAllTags();
      this.loading = false;
    },
    async deleteTag() {
      const response = await api.VitrinTags.deleteTag(this.tagId);
      if (response.type === "error") {
        showMessage(response.data.message);
      } else {
        showMessage("Тег удален", true);
        this.setValues();
      }
      this.showDelete = false;
    },
    async addNewTag() {
      if (!this.$refs.vitrinModal.validate()) {
        showMessage("Не все поля заполнены");
        return;
      }
      const response = await api.VitrinTags.createTag(this.newTag);
      if (response.type === "error") {
        showMessage(response.data.message);
      } else {
        const tagId = response.id;
        // Если выбрано изображение, загружаем его с ключом "imageFile"
        if (this.imageFile) {
          const formData = new FormData();
          formData.append("imageFile", this.imageFile);
          await api.VitrinTags.uploadTagImage(tagId, formData);
        }
        showMessage("Тег добавлен", true);
        this.setValues();
      }
      this.modal = false;
    },
    openAddModal() {
      this.modal = true;
      this.isRedacting = false;
      this.newTag = {};
      this.imageFile = null;
      this.preview = null;
      this.existingImage = null;
      // Сброс компонента v-file-input
      this.fileInputKey = Date.now();
    },
    openModalToRedact(tagObj) {
      // Заполняем форму данными выбранного тега
      this.newTag = {
        id: tagObj.id,
        name: tagObj.name,
        description: tagObj.description,
        productId: tagObj.product ? tagObj.product.id : null,
        categoryId: tagObj.category ? tagObj.category.id : null,
        departmentId: tagObj.department ? tagObj.department.id : null,
        pageLink: tagObj.pageLink,
        cityIds: tagObj.cityIds,
      };
      // Если тег имеет изображение, сохраняем его для предпросмотра
      this.existingImage = tagObj.imageUrl ? { url: tagObj.imageUrl, id: tagObj.id } : null;
      // Сбрасываем новое изображение
      this.imageFile = null;
      this.preview = null;
      // Сброс компонента v-file-input
      this.fileInputKey = Date.now();
      this.isRedacting = true;
      this.modal = true;
    },
    async changeTag() {
      if (!this.$refs.vitrinModal.validate()) {
        showMessage("Не все поля заполнены");
        return;
      }
      const response = await api.VitrinTags.changeTag(this.newTag);
      if (response.type === "error") {
        showMessage(response.data.message);
      } else {
        const tagId = response.id;
        // Если выбрано новое изображение, загружаем его
        if (this.imageFile) {
          const formData = new FormData();
          formData.append("imageFile", this.imageFile);
          await api.VitrinTags.uploadTagImage(tagId, formData);
        }
        showMessage("Тег успешно изменен", true);
        this.setValues();
      }
      this.isRedacting = false;
      this.modal = false;
    },
    changeField(name) {
      const arr = ["productId", "categoryId", "departmentId", "pageLink"];
      arr.forEach((item) => {
        if (item !== name) {
          this.newTag[item] = null;
        }
      });
    },
    onFileChange(file) {
      if (file && file.type === "image/svg+xml") {
        this.imageFile = file;
        const reader = new FileReader();
        reader.onload = (e) => {
          this.preview = e.target.result;
        };
        reader.readAsDataURL(file);
      } else {
        this.imageFile = null;
        this.preview = null;
        if (file) {
          showMessage("Пожалуйста, выберите корректный SVG файл.");
        }
      }
    },
    async deleteExistingImage() {
      if (!this.existingImage) return;
      try {
        await api.VitrinTags.deleteTagImage(this.existingImage.id);
        showMessage("Изображение удалено", true);
        this.existingImage = null;
        // Очищаем данные для загрузки изображения и сбрасываем v-file-input
        this.imageFile = null;
        this.preview = null;
        this.fileInputKey = Date.now();
        this.setValues();
      } catch (e) {
        showMessage("Ошибка при удалении изображения");
      }
    },
    // Метод для удаления изображения непосредственно из карточки тега
    async deleteTagImage(tag) {
      try {
        await api.VitrinTags.deleteTagImage(tag.id);
        showMessage("Изображение удалено", true);
        this.setValues();
      } catch (e) {
        showMessage("Ошибка при удалении изображения");
      }
    },
    closeModal() {
      this.modal = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.btns {
  display: flex;
  justify-content: center;
  gap: 20px;
  padding: 20px;
}
.conteiner {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  &__header {
    padding-top: 10px;
    font-size: 20px;
    font-weight: bold;
  }
  &__tag {
    padding: 5px 10px;
    border-radius: 10px;
    border: 1px solid #5d4bd0;
    box-sizing: border-box;
    width: calc(25% - 10px);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    &_title {
      font-size: large;
      font-weight: bold;
    }
  }
  &_icon:hover {
    color: black !important;
  }
  .tag-image-container {
    display: flex;
    align-items: center;
    gap: 5px;
  }
}
.modal {
  padding: 20px 50px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  &_title {
    justify-content: center;
  }
  &__text {
    padding: 0;
    margin: 0;
  }
  &_actions {
    justify-content: right;
  }
  &__checkboxes {
    display: flex;
    gap: 30px;
    @media screen and (max-width: 700px) {
      flex-wrap: wrap;
    }
  }
}
.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  &_title {
    width: 90%;
  }
}
</style>
